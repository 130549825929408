import React, { Suspense, useEffect } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import { Switch, Route, BrowserRouter as Router, useLocation } from 'react-router-dom';
import {
	PublicHeader,
	NotFound,
	Footer,
	Loading,
	Login,
	NotAuthorized,
	SearchForeclosurePage,
	CallCenter,
	LeadCapture,
	PropertyReport
} from '../components';

import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import { PERMS } from '../helper/perms';

const Signup = loadable(() => import('../components/pages/Signup'));
const Dashboard = loadable(() => import('../components/pages/Dashboard'));
const Dashboardv2 = loadable(() => import('../components/pages/Dashboardv2'));
const ResetPassword = loadable(() => import('../components/pages/ResetPassword'));
const About = loadable(() => import('../components/pages/About'));

const Audit = loadable(() => import('../components/pages/Audit'));
const SearchPage = loadable(() => import('../components/pages/SearchPage'));
const SearchActivePage = loadable(() => import('../components/pages/SearchActivePage'));
const DetailsPage = loadable(() => import('../components/pages/DetailsPage'));

const MyHomes = loadable(() => import('../components/pages/MyHomes'));
const MyTasks = loadable(() => import('../components/pages/MyTasks'));
const Organization = loadable(() => import('../components/pages/Organization'));
const FollowUps = loadable(() => import('../components/pages/FollowUps'));
const TourPage = loadable(() => import('../components/pages/TourPage'));
const MyRoutes = loadable(() => import('../components/pages/MyRoutes'));
const PlanRoutes = loadable(() => import('../components/pages/PlanRoutes'));
const VisitHomePage = loadable(() => import('../components/pages/VisitHomePage'));
const Settings = loadable(() => import('../components/pages/Settings'));
const CreateRoute = loadable(() => import('../components/pages/CreateRoute'));

const TitleUpdater = () => {
	const location = useLocation();
	const path = location.pathname;
	var capitalizedtitle = '';
	console.log(path);
	if (path !== '/') {
		const pathparts = path.split('/');
		const title = pathparts[2];
		if (title) {
			capitalizedtitle = title.charAt(0).toUpperCase() + title.slice(1); // Capitalize the first letter
		}
	} else {
		capitalizedtitle = 'Saige';
	}
	useEffect(() => {
		const { pathname } = location;
		document.title = capitalizedtitle + ' | Saige';
	}, [location]);

	return null; // Render nothing in the DOM for the TitleUpdater component
};

export default function Routes() {
	const VIEW_FORECLOSURE_PERM_ID = PERMS.VIEW_FORECLOSURE;
	const VIEW_DEAL_PERM_ID = PERMS.VIEW_DEALS;
	const SETTING_ACCESS_PERM_ID = PERMS.SETTING_ACCESS;
	const VIEW_OFFMARKET_SEARCH_PERM_ID = PERMS.VIEW_OFFMARKET_SEARCH;
	const VIEW_EDIT_HISTORY_PERM_ID = PERMS.VIEW_EDIT_HISTORY;
	return (
		<Router>
			<TitleUpdater />
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path={['/', '/login']} render={(props) => <Login {...props} />} />
					<Route path="/reset-password">
						<ResetPassword />
					</Route>
					<Route path="/about">
						<PublicHeader />
						<About />
						<Footer />
					</Route>
					<Route path="/signup">
						<Signup />
					</Route>
					{/* 					<PrivateRoute path="/user/dashboard">
						<Dashboard />
					</PrivateRoute> */}

					<PrivateRoute path="/user/dashboard">
						<Dashboardv2 />
					</PrivateRoute>
					<PrivateRoute path="/user/callcenter">
						<CallCenter />
					</PrivateRoute>
					<PrivateRoute path="/user/search">
						<AuthRoute
							perm={VIEW_OFFMARKET_SEARCH_PERM_ID}
							redirectPath="/user/routes"
							path="/user/search"
						>
							<SearchPage />
						</AuthRoute>
					</PrivateRoute>
					<PrivateRoute path="/user/search-active">
						<AuthRoute perm={VIEW_DEAL_PERM_ID} path="/user/search-active">
							<SearchActivePage />
						</AuthRoute>
					</PrivateRoute>
					<PrivateRoute path="/user/search-foreclosure">
						<AuthRoute perm={VIEW_FORECLOSURE_PERM_ID} path="/user/search-foreclosure">
							<SearchForeclosurePage />
						</AuthRoute>
					</PrivateRoute>
					<PrivateRoute path="/user/tour">
						<TourPage />
					</PrivateRoute>
					<PrivateRoute path="/user/collection">
						<MyHomes />
					</PrivateRoute>
					<PrivateRoute path="/user/task">
						<MyTasks />
					</PrivateRoute>
					<PrivateRoute path="/user/organization">
						<Organization />
					</PrivateRoute>
					<PrivateRoute path="/user/settings">
						<AuthRoute perm={SETTING_ACCESS_PERM_ID} path="/user/settings">
							<Settings />
						</AuthRoute>
					</PrivateRoute>
					<PrivateRoute path="/user/planning-routes">
						<PlanRoutes />
					</PrivateRoute>
					<PrivateRoute path="/user/creating-routes">
						<CreateRoute />
					</PrivateRoute>
					<PrivateRoute path="/user/routes">
						<MyRoutes />
					</PrivateRoute>
					<PrivateRoute path="/user/start-routes/:MLNum/:id">
						<DetailsPage />
					</PrivateRoute>
					{/* OLD DETAILS PAGE */}
					{/* <PrivateRoute path="/user/listing">
						<ListP />
					</PrivateRoute> */}

					{/* NEW DETAILS PAGE */}
					<PrivateRoute path="/user/listing">
						<DetailsPage />
					</PrivateRoute>
					{/* NEW DETAILS PAGE */}
					<PrivateRoute path="/user/home">
						<VisitHomePage />
					</PrivateRoute>

					<PrivateRoute path="/user/audit">
						<AuthRoute perm={VIEW_EDIT_HISTORY_PERM_ID} path="/user/audit">
							<Audit />
						</AuthRoute>
					</PrivateRoute>

					<PrivateRoute path="/user/follow-ups">
						<FollowUps />
					</PrivateRoute>

					<Route path="/403">
						<NotAuthorized />
						<Footer />
					</Route>
					<Route path="/leadcapture" component={LeadCapture}>
						<LeadCapture />
					</Route>
					<Route path="/propertyreport" component={PropertyReport}>
						<PropertyReport />
					</Route>
					<Route>
						<NotFound />
						<Footer />
					</Route>
				</Switch>
			</Suspense>
		</Router>
	);
}
