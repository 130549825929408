import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserState } from '../hook/customerHook';
import { BaseLayout, Loading } from '../components/index';
import { checkUserHasPermissionId } from '../api/serverRequests';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	}
}));
export default function AuthRoute({ perm, redirectPath, children, ...rest }) {
	const [userState, dispatchUser] = useUserState();
	const [isLoading, setIsLoading] = useState(true);
	const [auth, isAuth] = useState(false);
	const classes = useStyles();
	redirectPath = redirectPath || '/403';
	useEffect(() => {
		setIsLoading(true);
		checkUserHasPermissionId(perm, userState.user.user_id)
			.then((res) => {
				if (res.data.success) {
					isAuth(true);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.error(err.message);
				setIsLoading(false);
				isAuth(false);
			});
	}, [perm]);

	if (isLoading) {
		return (
			<BaseLayout>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Loading />
						</Paper>
					</Grid>
				</Grid>
			</BaseLayout>
		);
	} else {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					auth ? (
						children
					) : (
						<Redirect
							to={{
								pathname: redirectPath,
								state: {
									from: location
									// message:"Please sign in to contine"
								}
							}}
						/>
					)
				}
			/>
		);
	}
}
